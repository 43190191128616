import type Client from '../client'
import PaginatedResponse from '../paginated-response'
import type { ErrorAble, MediaResponse, RequestItemBag } from '@/api'

export default class Media {
  constructor(private client: Client) {}

  create(prefix: string, media: File[], single: boolean = false): Promise<ErrorAble<MediaResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        let data: any = {media};

        if (single) {
          data.single = single
        }

        resolve(
          await this.client.post(`media/${prefix}/upload`, { data: data }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  prefixes(): Promise<PaginatedResponse<{ data: string[] }>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.client.get('media/prefixes'))
      } catch (error) {
        reject(error)
      }
    })
  }

  go(
    page: number,
    query: RequestItemBag = {},
  ): Promise<PaginatedResponse<MediaResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          new PaginatedResponse(
            await this.client.get('media', { query: { ...query, page } }),
          ),
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  video(file: File, prefix: string = 'video'): Promise<ErrorAble<MediaResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
            await this.client.post(`upload/video/${prefix}`, { data: { file } }),
        )
      } catch (error) {
        reject(error)
      }
    })
  }
}
