import { Module } from 'vuex'
import { products as productsApi } from '@/api'

export const products: Module<{}, {}> = {
  namespaced: true,

  actions: {
    async listing(_, page) {
      const response = await productsApi.go(page)

      return response
    },
    async find(_, id) {
      try {
        const response = await productsApi.find(id, { withBase64: true })

        return response.data
      } catch (error) {
        // console.log('find -> error', error)
      }
    },
    async create(_, data) {
      const product = await productsApi.create(data)

      return product
    },
    async update(_, { id, ...data }) {
      await productsApi.update(id, data)
    },

    async updateThumb(_, { id, thumbnail, sizes }) {
      const response = await productsApi.thumbnail(id, thumbnail, {
        'X-Youwish-Product-Thumbnail-Sizes': JSON.stringify(sizes),
      })

      return response.data
    },

    async suppliers(_, { supplier_id }) {
      const variations = await productsApi.variations.all(null, {
        filter: { supplier_id: supplier_id },
        include: ['dates', 'template'].join(','),
      })

      return variations.data()
    },
  },
}
