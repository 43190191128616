import Client from './client'
import UserResource from './resources/user'
import PasswordResource from './resources/password'
import ProductResource from './resources/product'
import SupplierResource from './resources/supplier'
import CategoryResource from './resources/categories'
import ToplistResource from './resources/toplists'
import OrderResource from './resources/order'
import BookingResource from './resources/booking'
import PlaceResource from './resources/place'
import BaseResource from './resources/base'
import LocationResource from './resources/locations'
import TagsResource from './resources/tags'
import RolesResource from './resources/roles'
import GiftcardResource from './resources/giftcard'
import PagesResource from './resources/pages'
import InspirationsResource from './resources/inspirations'
import MediaResource from './resources/media'
import InvoiceResource from './resources/invoices'
import BookingDateResource from './resources/booking-dates'
import AccountResource from './resources/account'
import SalesTrendResource from './resources/sales-trend'
import ReportResource from './resources/report'

const client = new Client()
export const users = new UserResource(client)
export const passwords = new PasswordResource(client)
export const products = new ProductResource(client)
export const suppliers = new SupplierResource(client)
export const categories = new CategoryResource(client)
export const toplists = new ToplistResource(client)
export const orders = new OrderResource(client)
export const booking = new BookingResource(client)
export const dates = new BookingDateResource(client)
export const places = new PlaceResource(client)
export const location = new LocationResource(client)
export const base = new BaseResource(client)
export const tags = new TagsResource(client)
export const roles = new RolesResource(client)
export const giftcard = new GiftcardResource(client)
export const pages = new PagesResource(client)
export const inspirations = new InspirationsResource(client)
export const media = new MediaResource(client)
export const invoices = new InvoiceResource(client)
export const account = new AccountResource(client)
export const salesTrend = new SalesTrendResource(client)
export const report = new ReportResource(client)

export { Client }
export * from './types'
