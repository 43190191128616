<template>
  <div id="header" class="app-header">
    <!-- BEGIN mobile-toggler -->
    <div class="mobile-toggler">
      <button type="button" class="menu-toggler" v-on:click="toggleAppSidebarMobile">
        <span class="bar"></span>
        <span class="bar"></span>
      </button>
    </div>
    <!-- END mobile-toggler -->

    <!-- BEGIN brand -->
    <div class="brand">
      <div class="desktop-toggler">
        <button type="button" class="menu-toggler" v-on:click="toggleAppSidebarMinify">
          <span class="bar"></span>
          <span class="bar"></span>
        </button>
      </div>

      <router-link :to="{ name: 'dashboard' }" class="brand-logo">
        <c-img src="/img/logo.png" alt="" height="20" />
      </router-link>
    </div>
    <!-- END brand -->

    <!-- BEGIN menu -->
    <div class="menu">
      <global-search />

      <b-dropdown
        class="menu-item"
        right
        toggle-tag="a"
        variant="link"
        :no-caret="true"
        toggle-class="menu-link"
        menu-class="dropdown-notification"
      >
        <template v-slot:button-content>
          <div class="menu-icon"><i class="fa fa-bell"></i></div>
          <div v-if="notificationData.length" class="menu-label">
            {{ notificationData.length }}
          </div>
        </template>
        <h6 class="dropdown-header text-gray-900 mb-1">Notifications</h6>
        <template v-if="notificationData && notificationData.length > 0">
          <a
            href="#"
            class="dropdown-notification-item"
            v-for="(notification, index) in notificationData"
            v-bind:key="index"
          >
            <div class="dropdown-notification-icon">
              <i v-if="notification.icon" v-bind:class="notification.icon"></i>
              <img v-if="notification.image" v-bind:src="notification.image" width="26" />
            </div>
            <div class="dropdown-notification-info">
              <div class="title">{{ notification.title }}</div>
              <div class="time">{{ notification.time }}</div>
            </div>
            <div class="dropdown-notification-arrow">
              <i class="fa fa-chevron-right"></i>
            </div>
          </a>
        </template>
        <template v-else>
          <div class="dropdown-notification-item bg-white">
            No record found
          </div>
        </template>
        <div class="p-2 text-center mb-n1">
          <a href="#" class="text-gray-800 text-decoration-none">See all</a>
        </div>
      </b-dropdown>

      <b-dropdown
        class="menu-item"
        right
        toggle-tag="a"
        variant="link"
        :no-caret="true"
        toggle-class="menu-link border-0"
      >
        <template v-slot:button-content>
          <div class="menu-img online">
            <component :is="user.avatar_url ? 'img' : 'c-img'"
                       :src="user.avatar_url ? user.avatar_url : getDefaultAvatar()"
                       alt="" class="mw-100 mh-100 rounded-circle"/>
          </div>
          <div class="menu-text">{{ user.full_name }}</div>
        </template>
        <b-dropdown-item
          :to="{ name: 'users.profile', params: { id: user.id } }"
          link-class="d-flex align-items-center"
        >
          Profile
          <i class="fa fa-user-circle fa-fw ml-auto text-gray-400 f-s-16"></i>
        </b-dropdown-item>
        <b-dropdown-item
          :to="{ name: 'users.settings', params: { id: user.id } }"
          link-class="d-flex align-items-center"
        >
          Settings
          <i class="fa fa-wrench fa-fw ml-auto text-gray-400 f-s-16"></i>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          Log Out
          <i class="fa fa-toggle-off fa-fw ml-auto text-gray-400 f-s-16"></i>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <!-- END menu -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GlobalSearch from '../GlobalSearch'
import AppOptions from '../../config/app-options'

export default {
  name: 'HeaderComponent',
  components: { GlobalSearch },
  data() {
    return {
      appOptions: AppOptions,
      notificationData: [
        // {
        //   icon: 'fa fa-receipt fa-fw fa-lg text-success',
        //   title: 'Your store has a new order for 2 items totaling $1,299.00',
        //   time: 'just now',
        // },
        // {
        //   icon: 'fa fa-user-circle fa-fw fa-lg text-muted',
        //   title: '3 new customer account is created',
        //   time: '2 minutes ago',
        // },
        // {
        //   image: '/assets/img/icon/android.svg',
        //   title: 'Your android application has been approved',
        //   time: '5 minutes ago',
        // },
        // {
        //   image: '/assets/img/icon/paypal.svg',
        //   title: 'Paypal payment method has been enabled for your store',
        //   time: '10 minutes ago',
        // },
      ],
    }
  },
  computed: {
    ...mapGetters({ user: 'auth/user' }),
  },
  methods: {
    toggleAppSidebarMobile() {
      this.appOptions.appSidebarMobileToggled = !this.appOptions.appSidebarMobileToggled
    },
    toggleAppSidebarMinify() {
      this.appOptions.appSidebarMinified = !this.appOptions.appSidebarMinified
    },
    async logout() {
      await this.$store.dispatch('auth/logout')

      this.$router.go()
    },
    getDefaultAvatar() {
      return `${process.env.VUE_APP_BASE_URL}/img/user/default.png`
    },
  },
}
</script>
